import React from 'react'

import { MenuProvider, useMenuState } from './MenuProvider'
import { LightboxProvider, useLightboxState } from './LightboxProvider'

const ContextProvider = ({ children }) => (
    <MenuProvider>
        <LightboxProvider>{children}</LightboxProvider>
    </MenuProvider>
)

export { ContextProvider, useMenuState, useLightboxState }
